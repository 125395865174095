<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px"/>
                <span>{{l('228')}}</span>
            </div>
        </div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns"
                             :data-source="tableData"
                             :customRow="customRow"
                             :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
                             :rowKey="(record)=>record.id"
                             :scroll="{x:1000,y:scroll_x}"
                             :pagination="false"
                             size="small"
                             @change="handleTableChange"
                    >
						<span slot="name" slot-scope="text,record">
							<a @click="view1(record)">{{text}}</a>
						</span>
                        <span slot="enable" slot-scope="text">
                            <span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
                            <span v-else><a-icon type='close'/></span>
                            </span>
                        <span slot="auditNeedInput" slot-scope="text">
                            <span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
                            <span v-else><a-icon type='close'/></span>
                            </span>

                            <span slot="action" slot-scope="text,record">
                                <a @click="task_report(record)"><a-icon type="table" /></a>
                            </span>
                    </a-table>
                </a-spin>
                <a-pagination
                        class="pagination"
                        :total="totalItems"
                        showSizeChanger
                        showQuickJumper
                        :show-total="total => `共有 ${totalItems} 条`"
                        @change="onChangePage"
                        @showSizeChange="showSizeChange"/>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        AuditItemGroupServiceProxy,
        AudititemServiceProxy,
        AuditItemtypeServiceProxy,
        AuditTaskServiceProxy
    } from '../../../../shared/service-proxies';
    import {ModalHelper} from '@/shared/helpers';
    import {Dic, fileDownloadService} from "../../../../shared/utils";
    import moment from "moment";
    import view from "../../audit-task-view/audit-task-view.vue";
    import task_report from "../../audit-task/task_report/task_report.vue";

    let _this;
    export default {
        name: 'select-task',
        mixins: [ModalComponentBase],
        components: {
            view,
            task_report
        },

        created() {
            _this = this;
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
            this.AudititemServiceProxy = new AudititemServiceProxy(this.$apiUrl, this.$api);
            this.AuditItemtypeServiceProxy = new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        async mounted() {
            console.log(this.ids);
            try {
                this.loading = true;
                this.AuditTaskStateList = await Dic.getInstance().getDicAsync('AuditTaskState');
                this.AuditTaskTypeList = await Dic.getInstance().getDicAsync('AuditTaskType');
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
            this.getData();

            window.addEventListener('resize', this.changeHeight);
            this.changeHeight();
			
        },

        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,
                ids:[],
                filterText: undefined,
                totalItems: 0,//总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: {maxResultCount: 10, skipCount: 0},

                tableData: [],
                columns: [
                    // {
                    //     title: this.l('audit.planstart'),
                    //     width: 100,
                    //     dataIndex: 'planStart',
                    //     align: 'center',
                    // },
                    // {
                    //     title: this.l('audit.planend'),
                    //     width: 100,
                    //     dataIndex: 'planEnd',
                    //     align: 'center',
                    // },

                    {
                        title: this.l('Report'),
                        width: 50,
                        dataIndex: 'action',
                        align: 'center',
                        scopedSlots: {customRender: "action"},
                        fixed: 'left',
                    },

                    {
                        title: this.l('FomartDateTime'),
                        width: 150,
                        dataIndex: 'fomartDateTime',
                        align: 'center',
                    },

                    {
                        title: this.l('audit.audittasktypename'),
                        width: 90,
                        dataIndex: 'auditTaskTypeName',
                        align: 'center',
                    },
                    {
                        title: this.l('audit.name'),
                        width: 150,
                        dataIndex: 'name',
                        align: 'center',
                        scopedSlots: {customRender: "name"},
                        ellipsis: true
                    },
                    {
                        title: this.l('audit.inspectorname'),
                        width: 120,
                        dataIndex: 'inspectorName',
                        align: 'center',
                    },

                    {title: this.l('audit.starttime'), width: 150, dataIndex: 'startTime', align: 'center',},

                    {
                        title: this.l('audit.endtime'),
                        width: 150,
                        dataIndex: 'endTime',
                        align: 'center',
                    },


                    {
                        title: this.l('audit.audittaskstatename'),
                        width: 100,
                        dataIndex: 'auditTaskStateName',
                        align: 'center',
                    },
                    {
                        title: this.l('audit.remark'),
                        width: 100,
                        dataIndex: 'remark',
                        align: 'center',
                    },
                ],
                selectedRowKey: [], // 选择的数据Id
                selectedRowList: [],
                selectedRow: [],//选择的整行数据
                isLoading: false,//加载中
                children: [],
                treeData: [],
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                    code: 'code'
                },
                treeReplace: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                styObj: {
                    height: 0
                },

                checkedTreeKeys: [],
                isTreeEdit: false,
                isTreeCreate: false,
                isTreeLoading: false,//加载中
                treeNodeCode: undefined,
                expandedTreeKeys: [],
                selectedTreeKeys: [],
                selected: [],
                workProcessClass: undefined,
                workProcessData: [],
                teby: [],
                enable: [],
                _fileDownloadService: undefined,
                loading: false,

                AuditTaskStateList:[],
                AuditTaskTypeList:[],

                AllLPAUser:[],
                UserId:undefined,
                startdate:undefined,
                enddate:undefined,
                AuditTaskType:undefined,
            }
        },
        methods: {

            Leave(){
                if (this.selectedRowKey.length!=1){
                    abp.message.info('请选择一条记录');
                    return;
                }
                ModalHelper.create(leave, {
                    id:this.selectedRowKey[0]
                }, {width: 400}).subscribe(res => {
                    console.log(res)
                    this.getData();
                    this.selectedRowKey = [];
                })
            },

            task_report(record){

                ModalHelper.create(task_report, {
                    id:record.id
                }, {width: 1200}).subscribe(res => {
                    console.log(res)
                })
            },

            //排序
            handleTableChange(pagination, filters, sorter) {
                console.log(sorter);
                if (sorter.order != undefined){
                    this.Sorting = sorter.field + " " + sorter.order.replace("end","");
                }
                else{
                    this.Sorting = undefined;
                }
                this.getData();
            },

            timeChange(date, dateString) {
                this.startdate = date[0];
                this.enddate = date[1];
                this.getData();
            },

//div高度
            changeHeight() {
                this.styObj.height = window.innerHeight - 199 + 'px';
            },

            batchdelete() {
                if (this.selected <= 0 || this.selected == null) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }
                // console.log(this.children);
                // if (this.children[0] != undefined) {
                //     abp.message.warn(this.l("问题分类下存在问题分类，不可删除"));
                //     return;
                // }
                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this.AuditItemGroupServiceProxy.delete(this.selected).then(res => {
                                this.getTree();
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted"),
                                });
                            })
                        }
                    })
            },
            //获取数据
            getData() {
				this.selectedRowKey = [];
				this.selectedRowList = [];
                this.isLoading = true;
                this.AuditTaskServiceProxy.getPaged(
                    this.startdate,
                    this.enddate,
                    this.workProcessClass,
                    this.UserId,
                    this.AuditTaskType,
                    this.ids,
                    this.filterText ? this.filterText : undefined,
                    this.Sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.tableData = res.items;

                    this.tableData.map(item=>{
                        item.planEnd=item.planEnd?moment(item.planEnd).format("YYYY-MM-DD HH:mm"):"";
                        item.planStart=item.planStart?moment(item.planStart).format("YYYY-MM-DD HH:mm"):"";
                        item.startTime=item.startTime?moment(item.startTime).format("YYYY-MM-DD HH:mm"):"";
                        item.endTime=item.endTime?moment(item.endTime).format("YYYY-MM-DD HH:mm"):"";
                    });

                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                })
            },
            view(record) {
                ModalHelper.create(CreatErrorCode, {
                    isEdit: true,
                    id: record.auditTemplateId
                }, {
                }).subscribe(res => {
                    this.getData();
                    this.selectedRowKey = [];
                })
            },
            handleEdit(record) {
                // console.log(record);
                var entity = {
                    "id": record.id,
                };
                if (record.enable == true) {
                    this.enable = false;
                } else {
                    this.enable = true;
                }
                this.isLoading = true;
                this.AudititemServiceProxy.isDetelt(
                    this.enable,
                    entity,
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.getData();
                    this.selectedRowKey = [];
                })
            },
            //新增
            create(cases) {
                ModalHelper.create(createaudittask, {
                }, {width: 1000}).subscribe(res => {
                    console.log(res)
                    this.getData();
                    this.selectedRowKey = [];
                })
            },
            //编辑
            createOrEditM(record) {
                // console.log(record);
                ModalHelper.create(update, {
                    id: record.id
                }, {width: 1000}).subscribe(res => {
                    console.log(res)
                    this.getData();
                    // this.selectedRowKey = [];
                })
            },
			view1(record) {
				ModalHelper.create(view, {
				    id: record.id
				},{width:1000}).subscribe(res => {
				    console.log(res)
				    if(res){
				    	this.getData();
				    }
					this.selectedRowKey = [];
				})
			},
            //批量删除事件
            delete1() {
                if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }
                // if (this.children[0] != undefined) {
                //     abp.message.warn(this.l("审核点下存在审核点，不可删除"));
                //     return;
                // }
                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this.isLoading = true;
                            this.AuditTaskServiceProxy.batchDelete(this.selectedRowKey).then(res => {
                                this.isLoading = false;
                                this.getData();
                                this.selectedRowKey = [];
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted"),
                                });
                            })
                        }
                    })
            },


            //批量删除事件
            delete2(record) {
                let selectid = [];
                selectid.push(record.id);
                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("confirm_deletion"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this.AuditTaskServiceProxy.batchDelete(selectid).then(res => {
                                this.getData();
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted"),
                                });
                            })
                        }
                    })
            },

            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }

            },
            //多选
            SelectAll(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.tableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKey.find(t => t == item.id);
                        if (!u) {
                            this.selectedRowKey.push(item.id);
                            this.selectedRowList.push(item);
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKey = [];
                        this.selectedRowList = [];
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelect(record) {
                //debugger;
                record.checked = !record.checked;
                if (record.checked) {
                    this.selectedRowKey.push(record.id);
                    this.children.push(record.children);
                    this.selectedRowList.push(record);
                    this.name = record.name;
                    this.number = record.code;
                } else {
                    this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
                    this.children = _.difference(_this.children, [record.children]);
                    this.selectedRowList = _.difference(_this.selectedRowList, [record]);
                }
            },
            //搜索框点击事件
            filteronchange() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            AuditTaskStateChange(value) {
                console.log(value);
                this.workProcessClass = value;
                this.getData();
            },

            AuditTaskTypeChange(value) {
                console.log(value);
                this.AuditTaskType = value;
                this.getData();
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            //分页
            onChangePage(page, pageSize) {
                this.selectedRowKey = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            //正常分页展示形式，显示（共有 {0} 条）
            // showTotalFun() {
            // 	console.log(this.totalItems);
            //     return this.l(
            //         "GridFooterDisplayText",
            //         this.totalItems
            //     );
            // },
            showSizeChange(current, size) {
                this.selectedRowKey = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
                this.getData();
            },
            //导入
            Export() {
                ModalHelper.create(
                    ImportExcelModel,
                    {},
                    {
                        width: "800px"
                    }
                ).subscribe(res => {
                    this.getData();
                });
            },
            //导出
            handleMenuClick() {
                this.AudititemServiceProxy.getToExcelFile(
                    this.selectedRowKey
                ).then(res => {
                    this._fileDownloadService.downloadTempFile(res);
                })
            },
        },
    }
</script>
<style>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .con {
        padding-bottom: 10px;
    }
</style>
