<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row style="margin-bottom: 20px;">
                    <a-col :span="24">
                        <a-range-picker @change="timeChange" :placeholder="[l('audit.planstart'), l('audit.planend')]" :allowClear="false" v-model="defaultRangeTime"
                            style="margin-right:10px; width: 250px;" />
                        <a-tree-select style="width: 200px;margin-right: 10px;" :tree-data="PointData"
                            :replace-fields="treeReplace" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :placeholder="l('Please_select_a_audit_point')" allow-clear tree-default-expand-all
                            @change="PointChange">
                        </a-tree-select>
                        <a-select showSearch :filterOption="filterOption" style="width: 200px;text-align: left;margin-right: 10px;"
                            dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                            :placeholder="l('Please_select_a_label')" allow-clear mode="multiple" @change="AuditTaskLabelChange">
                            <a-select-option v-for="item in AuditTaskLabelList" :key="item.itemDetailCode"
                                :title="item.itemDetailName">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>
						<a-button type="primary" @click="getView">
						  {{ l('QueryUserDownloadConfig')}}
						</a-button>
                    </a-col>
                </a-row>
                <a-spin :spinning="isLoading">
                    <div id="data" style="height: 0px;text-align: center;font-size: 2rem;line-height: 100px;"></div>
                    <div style="height: 720px;width: 100%;text-align: left" id="mainScrapCode">

                    </div>
					<a-table :columns="columns" :data-source="tableData" :customRow="customRow"
					         :rowKey="(record)=>record.id" :scroll="{x:800,y:scroll_x}" :pagination="false" size="small" style="margin-top: 20px;">
					</a-table>
                </a-spin>
				<a-pagination
				    class="pagination"
				    :total="totalItems"
				    :show-total="total => `共有 ${totalItems} 条`"
				    showSizeChanger
				    showQuickJumper
				    @change="onChangePage"
				    @showSizeChange="showSizeChange"/>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
    import {
        AppComponentBase
    } from "@/shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        IssueServiceProxy, AuditTaskServiceProxy, AuditPointServiceProxy, ReportServiceProxy
    } from '../../../shared/service-proxies';
    import moment from "moment";
    import * as echarts from 'echarts';
    import { Dic, fileDownloadService } from "../../../shared/utils";
    import { ModalHelper } from '@/shared/helpers';
    import selecttask from "./select-task/select-task.vue";
	import task_report from "../audit-task/task_report/task_report.vue";
    let _this;
    export default {
        name: 'AuditResultSummary_Report',
        mixins: [AppComponentBase],
        components: {
            selecttask,
			task_report
        },
        created() {
            _this = this;
            this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
            this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
			this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
            this.AuditTaskLabelList = await Dic.getInstance().getDicAsync('AuditTaskLabel');
            this.getPointData();
			this.nowTime();
			this.getData1();
        },
        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,
                isLoading: true, //加载中
                year: moment(),
                yearDate: undefined,
                dataopen: false,
                typeCode: 1,
                month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                chartData: [],
                chartXaxisData: [],
                data: undefined,
                issueTitle: undefined,
                issueTypeTitle: undefined,
                xname: undefined,
                projectList: undefined,
                probType: undefined,
                bcBySeriesIndex: undefined,
                issueAreaTitle: undefined,
                proList: undefined,
                HeadTitle: undefined,
                areaList: [],
                problemNum: [],
                auditPointTitle: undefined,
                auditItemTitle: undefined,
                startdate: undefined,
                enddate: undefined,
				defaultRangeTime: [],

                // Issuelist: [],
                tableDatalength: 0,
                charttitlelist: [],
                chartvaluelist: [],

                AuditResultSummaryList: [],
                AuditTaskLabelList: [],
                AuditTaskLabel: undefined,
                treeDataList: [],
                treeReplace: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                PointId: undefined,
				pointIds: undefined,
                PointData: [],
				filterText: undefined,
				totalItems: 0, //总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "30", "40"],
				request: {
				  maxResultCount: 10,
				  skipCount: 0
				},
				
				tableData: [],
				columns: [{
				  title: this.l('auditpoint'),
				  width: 300,
				  dataIndex: 'name',
				  align: 'left',
				},
				  {
				    title: this.l('Schedule_Audits'),
				    width: 200,
				    dataIndex: 'planAuditCount',
				    align: 'center',
				  },
				  {
				    title: this.l('Number_Of_Audits'),
				    width: 200,
				    dataIndex: 'aclAuditCount',
				    align: 'center',
				    ellipsis: true
				  },
				  {},
				],
				selectedRowKey: [], // 选择的数据Id
				selectedRow: [], //选择的整行数据
            }
        },
        computed: {
            option3() {
                return {
                    title: {
                        text: this.HeadTitle,
                        subtext: '',
                        x: 'center',
                        textStyle: {
                            fontSize: 26,
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                        },
						formatter: data => {
							let formatter = data[0].axisValueLabel + '<br/><br/>';
							for (let i in data) {
								if (i<(data.length-1)) {
									if(data[i].value) {
										formatter += '<div style="width:10px;height:10px;display:inline-block;border-radius: 50%;background-color:'+data[i].color+'"></div>&nbsp;&nbsp;'+data[i].seriesName +'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+ Math.round(data[i].value * 100)+'%<br/>'
									}else {
										formatter += '<div style="width:10px;height:10px;display:inline-block;border-radius: 50%;background-color:'+data[i].color+'"></div>&nbsp;&nbsp;'+data[i].seriesName +'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NA<br/>'
									}
								}else {
									formatter += data[i].seriesName +'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+ Math.round(data[i].value)+'%'
								}
							}
							return formatter
						}
                        //   formatter: params => {
                        //     var html = [];

                        //     var category = {};
                        //     echarts['util'].each(params, function(param) {
                        //       var catName = param.seriesName;
                        //       var bc = bcBySeriesIndex[param.seriesIndex];
                        //       if (!category[catName]) {
                        //         category[catName] = {};
                        //       }
                        //       category[catName][bc] = param.value;
                        //     });
                        //     echarts['util'].each(category, function(cate, key) {
                        //       html.push(
                        //         '<tr>',
                        //         '<td>',
                        //         key,
                        //         '</td>',
                        //         '<td>',
                        //         cate.A,
                        //         '</td>',
                        //         '<td>',
                        //         cate.B,
                        //         '</td>',
                        //         '</tr>',
                        //       );
                        //     });

                        //     return (
                        //       '<table border=1><tbody>' +
                        //       '<tr><td></td><td>A(Find发现)</td><td>B(Close关闭)</td></tr>' +
                        //       html.join('') +
                        //       '</tbody></table>'
                        //     );
                        //   },
                    },
                    legend: {
                        data: this.proList,
                        top: '97%',
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    calculable: true,
                    xAxis: [{
                        type: 'category',
                        data: this.chartXaxisData,
                        nameGap: 30,
                        name: ' 日期 ',
                        splitLine: {
                            show: true
                        }
                    },],
                    yAxis: [{
                        type: 'value',
                        // name: this.l('问题数量(个)'),
                    }, {
                        type: 'value',
                        position: 'right',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                        // name: this.l('问题数量(个)'),
                    },],
                    series: this.chartData,
                }
            },


        },
        methods: {
			nowTime() {
				let aData = new Date();
				let startdate = new Date(aData.setFullYear(aData.getFullYear() - 1));
				let enddate = new Date();
				this.defaultRangeTime = [moment(startdate),moment(enddate)];
				this.startdate = startdate;
				this.enddate = enddate;
			},
			
            //获取审核点
            getPointData() {
                this.isLoading = true;
                this.AuditPointServiceProxy.getAllData(
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.PointData = JSON.parse(JSON.stringify(res));
                })
            },

            PointChange(key) {
                this.PointId = key;
				this.pointIds = [];
				this.pointIds.push(key);
                // document.getElementById("mainScrapCode")
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );2
            },

            AuditTaskLabelChange(value) {
                this.AuditTaskLabel = value;
            },

            timeChange(date, dateString) {
                this.startdate = date[0];
                this.enddate = date[1];
            },
			
			getView() {
				this.getData();
				this.getData1();
			},

            moment,
            getData() {
                this.isLoading = true;
                this.AuditTaskServiceProxy.getAuditResultSummary_Report(
                    this.startdate,
                    this.enddate,
                    this.PointId,
                    this.AuditTaskLabel
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    var ss = 0;
                    _this.AuditResultSummaryList = [];
                    for (let i = 0; i < res.length; i++) {
                        if (res[i].task_ReportList.length > 0) {
                            let entity = {
                                date: res[i].date,
                                itemlist: []
                            };
                            let tableData = res[i].task_ReportList;
                            let Issuelist = [];
                            let tableDatalength = 0;
                            let chartvaluelist = [];
                            let charttitlelist = [];
                            //审核项
                            if (tableData.length >= 2) {
                                tableDatalength = res[i].task_ReportList.length - 1;
                                tableData[0].task_Report_Type.forEach(element => {
                                    charttitlelist.push(element.auditItemTypeName);
                                });

                                let acltableDatalength = tableData.length - 1;
                                for (let index = 1; index < acltableDatalength + 1; index++) {
                                    let indecchirdlist = tableData[index].task_Report_Type;
                                    for (let indecchird = 0; indecchird < indecchirdlist.length; indecchird++) {
                                        if (Issuelist[indecchird] == undefined) {
                                            let a = {
                                                value: (acltableDatalength) - (indecchirdlist[indecchird].issueCount == 0 ? 0 : 1),
                                                tasklist: indecchirdlist[indecchird].auditTaskList
                                            }
                                            Issuelist.push(a)
                                            ss += (indecchirdlist[indecchird].issueCount == 0 ? 0 : 1);
                                        }
                                        else {
                                            let a = {
                                                value: (indecchirdlist[indecchird].issueCount == 0 ? 0 : 1),
                                                tasklist: indecchirdlist[indecchird].auditTaskList
                                            }
                                            Issuelist[indecchird].value -= a.value;
                                            ss += (indecchirdlist[indecchird].issueCount == 0 ? 0 : 1);
                                        }
                                    }
                                }
                            }


                            Issuelist.forEach(element => {
                                var ss = {
                                    value: Number((element.value / tableDatalength).toFixed(3)),
                                    tasklist: element.tasklist
                                }
                                chartvaluelist.push(ss)
                            });



                            entity.itemlist = chartvaluelist;
                            _this.charttitlelist = charttitlelist;
                            _this.AuditResultSummaryList.push(entity);

                        }
                    }
                    this.chartsData4();
                })
            },

			getData1() {
			  this.selectedRowKey = [];
			  this.isLoading = true;
			  this.ReportServiceProxy.getAuditPointRecord(
			      this.startdate,
			      this.enddate,
				  this.pointIds,
				  this.AuditTaskLabel,
			      undefined,
			      undefined,
			      this.request.maxResultCount,
			      this.request.skipCount
			  ).finally(() => {
			    this.isLoading = false;
			  }).then(res => {
			    console.log(res);
			    this.tableData = res.items;
			    // this.tableData.map(item=>{
			    //   item.creationTime=item.creationTime?moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss"):"";
			    // });
			
			    this.totalItems = res.totalCount;
			    this.totalPages = Math.ceil(
			        res.totalCount / this.request.maxResultCount
			    );
			    this.pagerange = [
			      (this.pageNumber - 1) * this.request.maxResultCount + 1,
			      this.pageNumber * this.request.maxResultCount,
			    ];
			  })
			},

            chartsData4() {
                var beifenbilist = [];
                var tempAuditResultSummaryList = [];
                _this.chartXaxisData = [];
                for (let i = 0; i < this.charttitlelist.length; i++) {
                    var tempitemlist = new Array(this.charttitlelist.length);
                    tempAuditResultSummaryList.push(tempitemlist);
                }
                for (let i = 0; i < _this.AuditResultSummaryList.length; i++) {
                    if (_this.AuditResultSummaryList[i].itemlist.length > 0) {
                        _this.chartXaxisData.push(_this.AuditResultSummaryList[i].date);
                        let s1 = 0;
                        let s2 = 0;
                        for (let index = 0; index < _this.AuditResultSummaryList[i].itemlist.length; index++) {
                            tempAuditResultSummaryList[index][i] = { value: _this.AuditResultSummaryList[i].itemlist[index].value, tasklist: _this.AuditResultSummaryList[i].itemlist[index].tasklist };
                            s1 += _this.AuditResultSummaryList[i].itemlist[index].value;
                            s2 += 1;
                        }
                        beifenbilist.push(Number(((s1 / s2) * 100).toFixed(0)));
                    }

                }
                this.chartData = [];
                var colorList = ['#1a3a75', '#9d9ea0', '#e1e1e1', '#7c7c7c', '#bac4d5', '#5e759e']
                for (var i = 0; i < tempAuditResultSummaryList.length; i++) {
                    var name = _this.charttitlelist[i];
                    var obj = {
                        name: name,
                        type: 'bar',
                        stack: 'A',
                        data: tempAuditResultSummaryList[i],
                        label: {
                            normal: {
                                show: true,
                                position: 'inside',
                                formatter: (data) => {
									return Math.round(data.value * 100) + '%'
								},
                                // textStyle: {
                                //     color: '#333'
                                // },
                            },
                        },
                        itemStyle: {//自定义颜色
                            normal: { color: colorList[i] },
                        },
                    };
                    this.chartData.push(obj);
                }
                var obj2 = {
                    name: "比例",
                    type: 'line',
                    yAxisIndex: 1,
                    data: beifenbilist,
                    label: {
						show: true,
						textBorderWidth: '0',
						formatter: function(data){
							if(data.value>0) {
								return data.value + '%'
							}else {
								return ""
							}
						}
					}
                };

                this.chartData.push(obj2);

                this.charts();
            },


            charts() {
                this.myeChart = echarts.init(document.getElementById("mainScrapCode")) //default)
				this.myeChart.off('click');
                this.myeChart.clear();
                document.getElementById("data").style.height = "0px";
                document.getElementById("data").innerHTML = "";
                // 使用刚指定的配置项和数据显示图表。
                document.getElementById("mainScrapCode").style.height = "720px";
                this.myeChart.setOption(this.option3);
                this.myeChart.on('click', function (params) {
                    let id = "";
                    params.data.tasklist.forEach(item => {
                        id = item.id
                    });
                    ModalHelper.create(task_report, {
                        id: id
                    }, { width: 1200 }).subscribe(res => {
                        // _this.getData();
                    })
                });

                this.myeChart.resize();
                window.addEventListener('resize', () => {
                    this.myeChart.resize();
                })
            },

            statusChange(value) {
                this.time = value;
                this.getData();
				this.getData1();
            },
            statusChange1(value) {
                this.typeCode = value;
                this.getData();
				this.getData1();
            },
            function() {
                this.yearDate = moment(new Date());
            },
            // 点击选择框事件 弹出日期组件选择年
            openChangeYear(status) {
                if (status) {
                    this.dataopen = true;
                } else {
                    this.dataopen = false;
                }
            },
            // 选择年之后 关闭弹框
            panelChangeYear(value) {
                this.yearDate = value;
                this.dataopen = false;
                this.getData();
				this.getData1();
            },
			//行选择
			customRow: (record) => {
			  return {
			    on: {
			      click: () => {
			        _this.onSelect(record)
			      }
			    }
			  }
			
			},
			//多选
			SelectAll(record, rows, crows) {
			  if (record) {
			    //更改表格数据勾选状态
			    this.tableData.map(item => {
			      item.checked = true;
			    });
			    rows.forEach((item, index) => {
			      //判断是否存在
			      let u = this.selectedRowKey.find(t => t == item.id);
			      if (!u) {
			        this.selectedRowKey = [...this.selectedRowKey, item.id];
			      }
			    });
			  } else {
			    //更改表格数据勾选状态
			    this.tableData.forEach((item, index) => {
			      item.checked = false;
			      this.selectedRowKey = [];
			    });
			  }
			},
			//分页
			onChangePage(page, pageSize) {
			  this.selectedRowKey = [];
			  this.pageNumber = page;
			  this.request.skipCount = (page - 1) * this.request.maxResultCount;
			  this.getData1();
			},
			//正常分页展示形式，显示（共有 {0} 条）
			// showTotalFun() {
			// 	console.log(this.totalItems);
			//     return this.l(
			//         "GridFooterDisplayText",
			//         this.totalItems
			//     );
			// },
			showSizeChange(current, size) {
			  this.selectedRowKey = [];
			  this.pageNumber = 1;
			  this.request.maxResultCount = size;
			  this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			  this.getData();
			},
			// 重写复选框点击事件(不可删除)
			onSelect(record) {
			  //debugger;
			  console.log(record);
			  record.checked = !record.checked;
			  if (record.checked) {
			    this.selectedRowKey.push(record.id);
			    this.children.push(record.children);
			  } else {
			    this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
			    this.children = _.difference(_this.children, [record.children]);
			  }
			},
        }
    }
</script>

<style>
</style>